import React from 'react';

const Contact = () => (
  <section className="contact">
    <h1>Contact</h1>
    <p>Feel free to reach out to me on my socials:</p>
    <ul>
      <li><a href="https://www.linkedin.com/in/dawidkocma" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
      <li><a href="https://github.com/dawidkocma" target="_blank" rel="noopener noreferrer">GitHub</a></li>
    </ul>
  </section>
);

export default Contact;
