import React from 'react';
import './About.css';
import profilePic from '../assets/profile-pic.jpg';
import TerminalSection from '../components/TerminalSection';

const About = () => (
  <section className="about">
    <div className="about-content">
      <img src={profilePic} alt="Dawid Koćma" className="profile-pic" />
      <p>
        Hi, I'm Dawid Koćma, an AI Developer specializing in machine learning and artificial intelligence. With a background in computer science and a passion for technology, I've been fortunate to work on cutting-edge projects that leverage AI to solve real-world problems. Yes... I wanted to bleed our eyes with this design. The hyperlinks are intentional as well. Have fun!
      </p>
    </div>
    <TerminalSection
      title="Education:"
      content="
        I hold a Master's Degree in Artificial Intelligence from The University of Manchester. Additionally, I have completed several certifications in machine learning and AI.
      "
    />
    <TerminalSection
      title="Skills and Expertise:"
      content="
        - Programming Languages: Python, JavaScript, Java, C++, Solidity <br />
        - Machine Learning: Scikit-learn, TensorFlow, Keras <br />
        - Data Visualization: Matplotlib, Seaborn, Pandas <br />
        - Web Development: React, Node.js
      "
    />
    <TerminalSection
      title="Personal Statement:"
      content="
        My professional philosophy is centered around continuous learning and innovation. I am driven by the potential of AI to transform industries and improve lives. My goal is to contribute to impactful AI solutions and collaborate with like-minded professionals. A true believer that discipline will take you where motivation will not. Staying up to date with new technologies.
      "
    />
    <TerminalSection
      title="Hobbies and Interests:"
      content="
        Outside of work, I enjoy exploring new technologies, participating in hackathons, and contributing to open-source projects. I also have a keen interest in web3 technologies.
      "
    />
    <TerminalSection
      title="Projects:"
      content="
        <strong>Personal Website:</strong> <a href='http://www.dawidkocma.com' target='_blank' rel='noopener noreferrer'>www.dawidkocma.com</a> (for additional information and projects) <br /><br />
        
        <strong>Sentiment Analysis of Social Media Users to Predict Price of Bitcoin</strong><br />
        - Brief Description: Developed a model to analyze social media sentiment to predict Bitcoin price trends.<br />
        - Technologies Used: Python, PyTorch, SQL<br />
        - Role: Lead Developer<br />
        - Summary: Leveraged Python for data processing, utilized PyTorch for building and training the sentiment analysis model, and employed SQL for data storage and retrieval.<br /><br />
        
        <strong>Intention Reading in Cognitive Robotics</strong><br />
        - Brief Description: Created a system for predicting intentions in cognitive robotics using dynamical clustering.<br />
        - Technologies Used: Python, Machine Learning, Robotics<br />
        - Role: Researcher<br />
        - Summary: Utilized Python to develop algorithms, applied machine learning techniques for dynamical clustering, and integrated robotics systems to predict and interpret cognitive intentions.<br /><br />
        
        <strong>Mind Controlled Robotic Arm</strong><br />
        - Brief Description: Designed and implemented a robotic arm controlled by brainwave signals.<br />
        - Technologies Used: Python, C, Robotics, EMotive EPoc<br />
        - Role: Developer<br />
        - Summary: Utilized Python and C for programming the control system, integrated robotics hardware, and processed brainwave signals to operate the robotic arm.<br /><br />
        
        <strong>S&P 500 Price Notification with SMS Alerts</strong><br />
        - Brief Description: Implemented a notification system for S&P 500 prices using SMS alerts.<br />
        - Technologies Used: Python, API<br />
        - Role: Developer<br />
        - Summary: Developed Python scripts to fetch S&P 500 data via API and integrated SMS alerts to notify users of price changes.<br /><br />
        
        <strong>To-Do List Web App</strong><br />
        - Brief Description: Developed a web application for managing to-do lists with cloud storage integration.<br />
        - Technologies Used: React, JavaScript, Flask, Cloud Storage<br />
        - Role: Developer
      "
    />
    <TerminalSection
      title="Contact:"
      content="
        Feel free to reach out to me on <a href='https://www.linkedin.com/in/dawidkocma' target='_blank' rel='noopener noreferrer'>LinkedIn</a> for collaboration, job opportunities, or just to connect.
      "
    />
  </section>
);

export default About;
