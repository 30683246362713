import React from 'react';

const Projects = () => (
  <section className="projects">
    <h1>Projects</h1>
    <p>I am building integrated live project viewer. Coming soon...</p>
  </section>
);

export default Projects;
