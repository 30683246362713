import React from 'react';
import './TerminalSection.css';

const TerminalSection = ({ title, content }) => (
  <div className="terminal">
    <h2>{title}</h2>
    <p dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br/>') }} />
  </div>
);

export default TerminalSection;

